import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {action} from 'mobx';
import './basket.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import { Swiper, SwiperSlide } from 'swiper/react';
import RecommendItem from "./Recommend/RecommendItem";

import {
    mindboxSetCart,
    mindboxGetRecommend,
    findDeliveryForOrder,
    helpFindCity,
    priceToUserString,
    sdekGetCityOffice,
    yandexGetCityOffice,
} from "../../http/basketApi";
import TrashBasket from "../../components/UI/trashBasket/TrashBasket";
import CalcBasket from "./CalcBasket/CalcBasket";
import {fetchCitysForOrderModal} from "../../http/settingsApi";
import DeliveryMethods from "./delivery/DeliveryMethods";
import OrderUserFields from "./userFieldsOrder/OrderUserFields";
import SelectPayType from "./SelectPayType/SelectPayType";
import YM from "../../components/YM";
import {Link, Navigate} from "react-router-dom";
import BasketOneStepCalc from "./BasketOneStepCalc/BasketOneStepCalc";
import {pushGoogleAnalyticProductAction} from "../../utils/googleAnalytic";
import {Helmet} from "react-helmet";
import ReceiptNotification from '../catalog/oneProductPage/receiptNotification';
import CloseIconMini from '../../components/UI/Icons/CloseIcon/CloseIconMini';
import BasketPromokod from '../../components/UI/basketPromokod/BasketPromokod';
import ArrowLeft from '../../components/UI/Arrows/ArrowLeft';
import ArrowRight from '../../components/UI/Arrows/ArrowRight';
import Heart from '../../components/UI/Heart';
import IsActiveCheckbox from '../../components/UI/isActiveCheckbox/isActiveCheckbox';
import Checkout from '../../components/checkout/Checkout';
import { Navigation } from 'swiper';
import Split from '../../components/Split/Split';
import OneProductCart from "../catalog/OneProductCart";
import Like from "../../components/UI/Like/Like";
import {pluralize} from "../../utils/format";

const loadScript = (src, onLoad) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    script.onload = onLoad;
};

var centerValue = [55.76, 37.64];
var placemarkArray = [];
 // Установка точек(смена цветов)
var activePlacemark


const init = () => {
    const myMap = new window.ymaps.Map("map", {
        // Координаты центра карты.
        // Порядок по умолчанию: «широта, долгота».
        // Чтобы не определять координаты центра карты вручную,
        // воспользуйтесь инструментом Определение координат.
        center: centerValue,
        // Уровень масштабирования. Допустимые значения:
        // от 0 (весь мир) до 19.
        zoom: 12
    },
        {
            searchControlProvider: 'yandex#search'
        },
    );
    placemarkArray.forEach((item,index) => {
        var myPlacemark = new window.ymaps.Placemark([item.latitude, item.longitude], {
            // Хинт показывается при наведении мышкой на иконку метки.
            hintContent: 'Содержимое всплывающей подсказки',
            // Балун откроется при клике по метке.
            balloonContent: "Адрес: "+item.address_full+"</br> </br> режим работы: "+item.work_time+"</br> </br>" + item.note +""
        }, {
            iconLayout: 'default#image',
            iconImageSize: [40, 52],
            iconImageHref: process.env.REACT_APP_API_URL+"uploads/images/ywdeliverypoint.png",
            iconImageOffset: [-3, -42],
            code: item.code
        });
        myPlacemark.events.add('click', function (e) {
            var eMap = e.get('target');
            var code = eMap.options._options.code;
            if(code) {
                let element = document.getElementById(code);
                console.log(element);

                if(element) {
                    element.click();
                    element.scrollIntoView({block: "start", behavior: "smooth"})

                    if(activePlacemark) {
                        activePlacemark.options.set('iconImageHref', process.env.REACT_APP_API_URL+"uploads/images/ywdeliverypoint.png");
                    }
                    activePlacemark = e.get('target');
                    activePlacemark.options.set('iconImageHref', process.env.REACT_APP_API_URL+"uploads/images/ywwhiteprint.png");
                }
            }
        });
        myMap.geoObjects.add(myPlacemark);
    })
    return myMap;
};

// После того как метка была создана, добавляем её на карту.
const Basket = observer(() => {
    const basketPullItems = (basketStorage) => {
        return basketStorage ? JSON.parse(basketStorage).map(function(basketItem) {
            return {
                id: basketItem.item.id,
                size: basketItem.currentSize,
                colorId: basketItem.colorId || basketItem.item.colorId || basketItem.item.color.id,
                count: basketItem.count,
                pricePerItem: basketItem.item.parrent_price
            };
        }) : []
    }
    const [modalActive, setModalActive] = useState(false)
    const [isAvtivePromo, setIsActivePromo] = useState(false)
    const [isUsePromo, setIsUsePromo] = useState(true)
    const {settings, user, basket, delivery} = useContext(Context);

    const [shippingMethodTab, setShippingMethodTab] = useState("list");
    const [activePageInformation, setActivePageInformation] = useState(null);
    const [preNotificationModalInfo,setPreNotificationModalInfo] = useState(null);
    const [successStateModal,setSuccessStateModal] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false)
    const [chosenProduct, setChosenProduct] = useState(null);
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const [basketStorage, setBasketStorage] = useState(window.localStorage.getItem('basketStorage') || [])
    const [isChecked, setIsChecked] = useState(false);
    const [recommend, setRecommend] = useState(false);

    const errRef = useRef(null)
    // useEffect(()=>{
    //     basket.setBasket(basket.activeBasketItems.slice(0,1))
    // },[checked])
 
    const [promoActivateCode, setPromoActiveteCode] = useState(basket.promocode??"");



    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    }, [])

    useEffect(() => {
        mindboxGetRecommend(deviceUUID, userAgent).then(response => {
            if(response.items){
                setRecommend(response.items)
            }
        })
    }, [])


    useEffect(() => {
        setBasketStorage(window.localStorage.getItem('basketStorage'))
    }, [])

    useEffect(() => {
        if (deviceUUID && userAgent) {
            mindboxSetCart(deviceUUID, userAgent, basketPullItems(basketStorage))
        }
    }, [basketStorage])


    const handlerTargetValue = (e) => {
        let newStringTrim = e.trim()
        setPromoActiveteCode(newStringTrim);
    }
    const isPromoHandler = (value) => {
        basket.setPromocode(value);
    }
    const addToFieldsProductModal = (activeChild, userId, activeSizeChildProduct, count) => {

        setChosenProduct(activeChild);
        setPreNotificationModalInfo({
            child_product_id: activeChild.id,
            colorId: activeChild.colorId,
            count: 1,
            currentSize: activeSizeChildProduct,
            item: activeChild,
            productId: activeChild.product.id,
        })
        setSuccessStateModal(false)
        setOpenNotificationModal(true);
        user.setShadowOverlay(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        let reduceInform = basket.information.filter(item => basket.startBasketNum === item.num)[0];
        setActivePageInformation(reduceInform)

        fetchCitysForOrderModal().then(response => {
            if (response.error) {
                console.log('Ошибка #0005')
                return false;
            }
            setCityForOrder(response)
        })

        basket.setStartBasketNum(1);
        delivery.setActive(null);
        basket.setDeliveryPrice(0);

        YM(45473325, 'reachGoal', 260925825)

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        const filterResult = basket.information.filter(item => item.num === basket.startBasketNum);
        if (filterResult[0]) {
            setActivePageInformation(filterResult[0])
        }

    }, [basket.startBasketNum])

    useEffect(() => {
        if(!basket.activeBasketItems.length)
            return

        if(basket.startBasketNum !== window.startBasketNum)
            window.startBasketNum = basket.startBasketNum
        else
            return

        const googleAnalyticBasketItems = basket.activeBasketItems.map((basketItem) => ({...basketItem.item, currentSize: basketItem.currentSize, customProps: {quantity: basketItem.count}}))
        let action = ''

        if(basket.startBasketNum === 1)
            action = 'view_cart'

        if(basket.startBasketNum === 2)
            action = 'begin_checkout'

        if(action)
            pushGoogleAnalyticProductAction(action, googleAnalyticBasketItems)

        if(isChecked){
            selectAll(true);
        }


    }, [basket.startBasketNum, basket.basketItems])

    useEffect(() => {
        if(basket.startBasketNum === 1){
            selectAll(true);
        }
    }, [basket.startBasketNum])

    useEffect(() => {
        if(basket.selectedItems.length === basket.activeBasketItems.length){
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }, [basket.selectedItems]);

    useEffect(() => () => window.startBasketNum = -1, [])

    const changeCountAuthController = (modifier, item) => {
        basket.chenageCountItem(modifier, item);
    }

    const deleteAuthItemController = (item) => {
        basket.removeItem(item);
    }

    const setSelectItem = (item) => {
        let index = basket.basketItems.indexOf(item)
        basket.basketItems[index].selected = !item.selected;
        const newActiveBasketItems = basket.basketItems.filter(item => item.selected);
        basket.setActiveBasketItems(newActiveBasketItems)
    }

    const selectAll = (state) => {
        basket.selectAll(state);
    }

    const findDelivery = async(item) => {
        const response = await findDeliveryForOrder(item, basket.hasGiftOnly);
        if(response.error || !response.length) {
            delivery.setAllMethods([]);
            user.setShadowOverlay(false)
            return;
        }
        user.setShadowOverlay(false);
        delivery.setAllMethods(response);
        delivery.setActive(null);
    }


    useEffect(() => {

        findDelivery(delivery.cityInfo)

    }, [delivery.cityInfo, basket.hasGiftOnly])

    const [cityForOrder, setCityForOrder] = useState(null);

    const setSityHandler = (item) => {
        if(!item.data) {
            item = {value:item.name, data:item}
        }

        delivery.setCityInfo(item);
        delivery.setCityInfoError('');
        delivery.setActive(null);
        delivery.setPrice(0);
        delivery.clearCustomerInfo();

        placemarkArray = [];
        setOfficeList([]);

    }

    //Выбор города - 1

    const callChangeCityModal = (number) => {
        user.setShadowOverlay(true)
        basket.setFunctionalModalState(number);
    }

    const closeFucntionalModal = () => {
        basket.setFunctionalModalState(null);
        user.setShadowOverlay(false)
    }

    useEffect(() => {
        if (user.shadowOverlay !== true) {
            basket.setFunctionalModalState(null);
        }
    }, [user.shadowOverlay])


    const [searchCityValue, setSearchCityValue] = useState(null);
    const [dadataFindResponse, setDadataFindResponse] = useState(null);

    const getOfferId = (item) => {
        let sizeId = 0;
        for(var i in item.item.child_product_size){
            let size = item.item.child_product_size[i]
            if(size.size_name == item.currentSize){
                sizeId = size.id
                break
            }
        }

        return item.item.id.toString() + item.item.color.id.toString() + sizeId.toString();
    }

    useEffect(() => {
        helpFindCity(searchCityValue).then(response => {
            const data = JSON.parse(response);
            if (data.suggestions.length) {
                setDadataFindResponse(data.suggestions)
                return false;
            }
            setDadataFindResponse(null)
        })

    }, [searchCityValue])
    // END DELIVERY STEP

    const [officeList, setOfficeList] = useState(null);

    const basketGoOneStep = async () => {

        const result = await basket.upStepPage(basket.startBasketNum)
        if(!result) {
            window.scrollTo(0, 0)
            return false;
        }

        if(result.order) {
            Navigate("/order/id/"+result.order.custom_order_id);
        }
    }

    useEffect(() => {
        if(basket.functionalModalState === 2) {
            if (delivery.active?.delivery_type === 'sdek_pvz') {
                sdekGetCityOffice(delivery.cityInfo).then(response => {

                    if(response.error) {
                        return false;
                    }

                    if(response.office) {
                        setOfficeList(response.office);
                        delivery.setPrice(response.deliveryPrice.delivery_sum)
                        delivery.setTerms({
                            calendar_min: response.deliveryPrice.calendar_min,
                            calendar_max: response.deliveryPrice.calendar_max
                        })
                        placemarkArray = [];
                        response.office.forEach((item,index) => {
                            placemarkArray.push({
                                code: item.code,
                                latitude: item.location.latitude,
                                longitude: item.location.longitude,
                                note: item.note ? 'Как добраться: ' + item.note : 'нет комментария',
                                work_time: item.work_time,
                                address_full: item.location.address_full
                            })
                        })
                        centerValue = [delivery.cityInfo.data.geo_lat, delivery.cityInfo.data.geo_lon];
                        loadScript("https://api-maps.yandex.ru/2.1/?apikey=ac12f8b6-c9dc-447c-a20d-80c0689a51c0&lang=ru_RU", () => {
                            window.ymaps.ready(init);
                        });
                    }
                });
            }

            if (delivery.active?.delivery_type === 'yandex_pvz') {
                yandexGetCityOffice(delivery.cityInfo).then(response => {
                    const {points} = response;
                    if (points?.length) {
                        placemarkArray = points;
                        setOfficeList(points);
                    }
                    centerValue = [delivery.cityInfo.data.geo_lat, delivery.cityInfo.data.geo_lon];

                    loadScript("https://api-maps.yandex.ru/2.1/?apikey=ac12f8b6-c9dc-447c-a20d-80c0689a51c0&lang=ru_RU", () => {
                        window.ymaps.ready(init);
                    });
                })
            }
        }
    }, [basket.functionalModalState]);


    const selectDeliverySdekHandler = () => {
        user.setShadowOverlay(false);
    }

    const ucFirst = (str) => {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    }

    const deleteSelected = () => {
        let items = basket.basketItems.filter(item => item.selected)
        if(items.length == basket.basketItems.length){
            basket.removeAllItems('active', false)
        } else {
            items.forEach(item => {
                basket.removeItem(item)
            })
        }
    }

    const checkSelectAll = () => {
        let state = !isChecked
        selectAll(state)
        setIsChecked(state)
    }

    return (
        <div style={{marginTop: settings.headerHeight}} className={"basket_page"}>
            <ReceiptNotification
                setOpenNotificationModal={e=> setOpenNotificationModal(e)}
                openNotificationModal={openNotificationModal}
                preNotificationModalInfo={preNotificationModalInfo}
                setPreNotificationModalInfo={e=> setPreNotificationModalInfo(e)}
                oneProduct={chosenProduct}
                setSuccessStateModal={e=> setSuccessStateModal(e)}
                successStateModal={successStateModal}
                />

            <Helmet>
                <title>Корзина интернет-магазина YOU WANNA</title>
                <meta name="description" content="Корзина в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" />
            </Helmet>
            <div
                className={basket.functionalModalState ? "basket_special_fucntional_modal active" : "basket_special_fucntional_modal"}>
                {
                    settings.projectWidth &&  settings.projectWidth > 768 ?
                        <div className={"map_container_sdek"}>
                            {
                                basket.functionalModalState === 2 ?
                                    <div className={"map_container_for_switch"} >
                                        <div id="map">
                                        </div>
                                    </div>
                                : ""
                            }
                        </div>
                    : ""
                }
                <div className={"container_to_switch_modal_state"}>
                    <div className={"basket_functional_modal_for_close"}>
                        {
                            basket.functionalModalState === 2 ?   <div className={"modal_state_choose_pvz_heading"}>Выберите пункт выдачи</div> : ""
                        }
                        <div
                            onClick={e => closeFucntionalModal()}
                            className={"basket_order_fucntional_exit_div"}>
                        </div>
                    </div>
                    {
                        basket.functionalModalState === 1 ?
                            <div className={"city_changer_function_modal_wrapper"}>
                                <div className={"functional_modal_support_wrapper"}>
                                    <input
                                        value={searchCityValue ? searchCityValue : ""}
                                        onChange={e => setSearchCityValue(e.target.value)}
                                        placeholder={"Найти город"}
                                        className={"search_city_input_fuctional_modal"}
                                        type="text"/>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.10812 0C12.5861 0 16.2162 3.63012 16.2162 8.10811C16.2162 9.95687 15.5974 11.6612 14.5557 13.0252L19.6834 18.1545C20.1055 18.5767 20.1055 19.2612 19.6834 19.6834C19.2936 20.0731 18.6805 20.103 18.2563 19.7733L18.1545 19.6834L13.0252 14.5557C11.6612 15.5974 9.95688 16.2162 8.10812 16.2162C3.63012 16.2162 0 12.5861 0 8.10811C0 3.63012 3.63012 0 8.10812 0ZM8.10812 2.16216C4.82426 2.16216 2.16217 4.82425 2.16217 8.10811C2.16217 11.392 4.82426 14.0541 8.10812 14.0541C11.392 14.0541 14.0541 11.392 14.0541 8.10811C14.0541 4.82425 11.392 2.16216 8.10812 2.16216Z" fill="black"/>
                                    </svg>
                                </div>
                                {
                                    cityForOrder && !searchCityValue ?
                                        <div className={"favorite_citys_functional_modal"}>
                                        <span
                                            className={"we_can_take_city"}> Вы можете выбрать более 150 000 городов. </span>
                                            <div className={"citys_map_wrapper"}>
                                                {
                                                    cityForOrder.map((item, index) => {
                                                        return (
                                                            <div key={item.name}
                                                                onClick={ e => setSityHandler(item,'custom')}
                                                                className={"one_city_functional_modal"}>г. {item.name}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    : ""
                                }
                                {
                                    searchCityValue && dadataFindResponse ?
                                        <div className={"order_response_city_list"}>
                                            {
                                                dadataFindResponse.map((item, index) => {
                                                    return (
                                                        <div
                                                            onClick={ e => setSityHandler(item)}
                                                            className={"order_modal_one_city_item"} key={item.value}>
                                                            <span className={"order_modal_one_city_item_name"}>{item.value}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> 
                                    : ""
                                }
                            </div>
                        : ""
                    }
                    {
                        basket.functionalModalState === 2 ?
                            <div className={"modal_state_choose_pvz"}>
                                <span className={"modal_satate_choose_pvz_city_info"}>{delivery.cityInfo ?  delivery.cityInfo.value : ""}</span>
                                <div className="modal_state_choose_pvz_tabs">
                                    <button className={'modal_state_choose_pvz_tab' + (shippingMethodTab === "list" ? " active" : "")} onClick={() => setShippingMethodTab("list")}>Список</button>
                                    <button className={'modal_state_choose_pvz_tab' + (shippingMethodTab === "map" ? " active" : "")} onClick={() => setShippingMethodTab("map")}>Карта</button>
                                </div>
                                
                                {
                                    officeList && (delivery.type === 'sdek_pvz' || delivery.type === 'yandex_pvz') ?
                                        <div className={"choose_office_pvz_list"} style={{
                                            display: ((settings.projectWidth && settings.projectWidth > 768) || shippingMethodTab === "list") ? "block" : "none"
                                        }}>
                                            {
                                                delivery.type === 'sdek_pvz' ?
                                                officeList.map((item,index) => {
                                                        return(
                                                            <div
                                                                onClick={e => delivery.setOfficePvz(item)}
                                                                id={item.code}
                                                                className={delivery.officePvz?.code === item.code ? "one_office_pvz_choose active" : "one_office_pvz_choose"}
                                                                key={index}
                                                                >
                                                                    <div className={"one_office_pvz_active_check"}>
                                                                        <div className={"one_office_pvz_circle"}>
                                                                            <span className={"active_pvz_circle"}></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"one_office_pvz_right_content"}>
                                                                        <span className={"one_office_pvz_small_heading"}>СДЭК</span>
                                                                        <div className={"one_office_pvz_address"}>
                                                                            {item.location?.address}
                                                                        </div>
                                                                        {
                                                                            delivery.terms  ?
                                                                                <div className={"one_office_pvz_support_price_wrapper"}>
                                                                                    <div className={"one_office_pvz_price"}>
                                                                                        Стоимость : {!basket.delPrice ? "бесплатно" : priceToUserString(basket.delPrice)+ " ₽" }
                                                                                    </div>
                                                                                    <div className={"one_office_pvz_price"}>
                                                                                        Срок доставки: от { delivery.terms.calendar_min } до { delivery.terms.calendar_max } рабочих дней.
                                                                                    </div>
                                                                                </div>
                                                                                : ""
                                                                        }
                                                                        <div className={"one_office_pvz_work_time"}>
                                                                            <span className={"pvz_work_time_heading"}>Режим работы: </span>
                                                                            <div className={"one_office_time"}>{item.work_time}</div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        )
                                                    })
                                                : 
                                                    officeList.map((item,index) => {
                                                        return(
                                                            <div
                                                                onClick={e => delivery.setOfficePvz(item)}
                                                                id={item.code}
                                                                className={delivery.officePvz?.code === item.code ? "one_office_pvz_choose active" : "one_office_pvz_choose"}
                                                                key={index}
                                                            >
                                                                <div className={"one_office_pvz_active_check"}>
                                                                    <div className={"one_office_pvz_circle"}>
                                                                        <span className={"active_pvz_circle"}></span>
                                                                    </div>
                                                                </div>
                                                                <div className={"one_office_pvz_right_content"}>
                                                                    <span className={"one_office_pvz_small_heading"}>Yandex</span>
                                                                    <div className={"one_office_pvz_address"}>
                                                                        {item.address_full}
                                                                    </div>
                                                                    { <div className={"one_office_pvz_work_time"}>
                                                                        <span className={"pvz_work_time_heading"}>Режим работы: </span>
                                                                        <div className={"one_office_time"}>{item.work_time}</div>
                                                                    </div> }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                            }
                                        </div>
                                    : ""
                                }
                                <div className={"map_container_sdek"} style={{
                                    display: settings.projectWidth &&  settings.projectWidth <= 768 && shippingMethodTab === "map" ? "block" : "none"
                                }}>
                                    {
                                        basket.functionalModalState === 2 ?
                                            <div className={"map_container_for_switch"} >
                                                <div id="map">
                                                </div>
                                            </div>
                                        : ""
                                    }
                                </div>
                                <div
                                    onClick={e => selectDeliverySdekHandler()}
                                    className={ delivery.officePvz ? "select_this_pvz" : "select_this_pvz disactive"}> Привезти сюда </div>
                            </div>
                        : ""
                    }
                </div>
            </div>

            {
                activePageInformation && basket.activeBasketItems.length > 0?
                    <div className={"container_basket_page"}>
                    <div className={"content_basket_page_wrapper"}>
                        <div className={modalActive ? "basket_modal active" : "basket_modal"} onClick={() => setModalActive(false)}>
                        <div className={"basket_modal_container"} onClick={e => e.stopPropagation()}>
                        <div className={"basket_modal_header"}>
                            <button className={"basket_modal_header_close"} onClick={() => setModalActive(false)}>
                               < img alt='' src='/files/images/close.svg' />
                               </button>
                            </div>
                            <div className={"basket_modal_body"}>
                            <span>Удалить товар из корзины?</span>
                            <button className={"basket_modal_btn basket_modal_btn_black"} onClick={() => {deleteSelected(); setModalActive(false)}}>Да, удалить</button>
                            <button className={"basket_modal_btn basket_modal_btn_white"} onClick={() => setModalActive(false)}>Вернуться</button>
                            <button></button>

                        </div>
                            </div>
                        </div>
                        {
                        basket.startBasketNum === 1 ?
                            <div className={"basket_one_step"}>
                            {
                                 basket.startBasketNum === 1 ?
                                     <div className={"basket_and_order_container_wrappers"}>
                                         <div className={"basket_and_order_column"}>
                                         <div className={"basket_page_main_block"}>
                                         <div className={"basket_count_items_and_clear_line"}>
                                                    <span className={"basket_page_counter"}>Корзина</span>

                                             {
                                                 basket.selectedItems.length > 0 ?
                                                     <div className={"basket_page_clear_basket_wrapper"}>
                                                         <CloseIconMini />
                                                         <span
                                                             onClick={( ) => setModalActive(true)}
                                                             // onClick={e => basket.removeAllItems('active')}
                                                             className={"basket_page_clear_basket"}>

                                                            Удалить выбранные
                                                         </span>
                                                     </div>    : ""
                                             }

                                                        <label className='basket_page_checkbox basket_page_checkbox_center '>
                                                            <span>Выбрать все</span>
                                                            <input checked={isChecked} onChange={() => checkSelectAll()}  type='checkbox' />
                                                            <span className={"basket_page_fake_checkbox"}></span>
                                                        </label>
                                        </div>
                                         <div className={"basket_page_for_product_list"}>
                                                    {
                                                        basket.basketItems.length > 0 ?
                                                        basket.basketItems.map((item, index) => {
                                                                // item = getBasketItem(item)
                                                                const favoriteItem = basket.favoriteItems?.find((fav) => fav.id === item.item.id)
                                                                return (
                                                                    <div id={getOfferId(item)} key={index}
                                                                        className={"basket_page_one_item_container"}>
                                                                        <label className='basket_page_checkbox basket_page_one_item_checkbox'>
                                                                            <input checked={item.selected} onChange={e => setSelectItem(item)} type='checkbox' />
                                                                            <span className={"basket_page_fake_checkbox"}></span>
                                                                        </label>
                                                                        <div className={"basket_page_image_preview"}>
                                                                            <div className={"basket_page_image_like"}>
                                                                                {
                                                                                    favoriteItem?
                                                                                        <Like
                                                                                            itemProduct={{...favoriteItem, id: favoriteItem.product.childProducts[0].id, color: favoriteItem.product.childProducts[0].color}}
                                                                                        />
                                                                                        :""
                                                                                }

                                                                            </div>
                                                                            <img
                                                                                src={process.env.REACT_APP_API_URL + "uploads/images/" + item.item.product.id + "/" + item.item.id + "/" + "214_" + item.item.img_preview}
                                                                                alt={'YouWanna - ' +(item.item.name ? item.item.name : '')}/>
                                                                        </div>

                                                                        <div className={"basket_page_container_price_and_basket"}>
                                                                            <div className={"basket_page_one_item_content_section"}>
                                                                                <div className={"basket_page_content_text_information"}>
                                                                                    <div className={"product_name_articul_color"}>
                                                                                        <div className={"basket_page_name_container"}>
                                                                                            {ucFirst(item.item.name)}
                                                                                        </div>
                                                                                        <div className={"basket_page_articul_container"}>
                                                                                            <span>Артикул: {" " + item.item.product.articul}</span>
                                                                                        </div>
                                                                                        {
                                                                                            settings.projectWidth && settings.projectWidth < 1025 ?
                                                                                                <div className={"basket_page_price_wrapper"}>
                                                                                                    <span className={item.item.sell_price ? "basket_page_price_string old_price" : ""}>{priceToUserString(item.item.product.price * item.count) + " ₽"}</span>
                                                                                                    <span className={"basket_page_old_price"}>{item.item.sell_price ? priceToUserString(item.item.sell_price * item.count)  + " ₽": ""}</span>
                                                                                                </div>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                        {
                                                                                            settings.projectWidth && settings.projectWidth > 768 ?
                                                                                                <div className={"basket_page_count_controller"}>
                                                                                                    <div className={"basket_page_content_line_size counter"}>

                                                                                                        <span className={"name_line_basket_page"}>Кол-во:</span>
                                                                                                        <div
                                                                                                            className={"count_one_product_controller_basket_page"}>
                                                                                                            <div
                                                                                                                onClick={e => changeCountAuthController("decrease", item)}
                                                                                                                className={"count_one_item_controller_basket_page min"}>

                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={"count_one_item_controller_basket_page count"}>{item.count}</div>
                                                                                                            <div
                                                                                                                onClick={e => changeCountAuthController("increase", item)}
                                                                                                                className={"count_one_item_controller_basket_page max"}>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                ""
                                                                                        }

                                                                                    </div>
                                                                                    <div className={"product_size_and_count"}>
                                                                                        <div className={"basket_page_color_container"}>
                                                                                            <span className={"basket_page_color_label"}>Цвет:</span>
                                                                                            <span className={"basket_page_color_props"}>{item.item.color.color_name}</span>
                                                                                        </div>
                                                                                        <div className={"basket_page_size_container"}>
                                                                                            <span className={"basket_page_size_label"}>Размер:</span>
                                                                                            <span className={"basket_page_size_props"}>{item.currentSize}</span>
                                                                                        </div>
                                                                                        {
                                                                                            settings.projectWidth && settings.projectWidth < 769 ?
                                                                                                <div className={"basket_page_count_controller"}>
                                                                                                    <div className={"basket_page_content_line_size counter"}>

                                                                                                        <span className={"name_line_basket_page"}>Кол-во:</span>
                                                                                                        <div
                                                                                                            className={"count_one_product_controller_basket_page"}>
                                                                                                            <div
                                                                                                                onClick={e => changeCountAuthController("decrease", item)}
                                                                                                                className={"count_one_item_controller_basket_page min"}>

                                                                                                            </div>
                                                                                                            <div
                                                                                                                className={"count_one_item_controller_basket_page count"}>{item.count}</div>
                                                                                                            <div
                                                                                                                onClick={e => changeCountAuthController("increase", item)}
                                                                                                                className={"count_one_item_controller_basket_page max"}>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        onClick={e => deleteAuthItemController(item)}
                                                                                                        className={"basket_page_trash_container"}>
                                                                                                        <TrashBasket/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                ""
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    settings.projectWidth && settings.projectWidth > 768 ?
                                                                                        <div
                                                                                            className={"basket_page_count_controller_and_price_wrapper"}>

                                                                                            <div
                                                                                                className={"basket_page_price_and_delete_wrapper"}>
                                                                                                {
                                                                                                    settings.projectWidth && settings.projectWidth > 1024 ?
                                                                                                        <div className={"basket_page_price_wrapper"}>
                                                                                                            <span className={item.item.sell_price ? "basket_page_price_string old_price" : ""}>{priceToUserString(item.item.product.price * item.count) + " ₽"}</span>
                                                                                                            <span className={"basket_page_old_price"}>{item.item.sell_price ? priceToUserString(item.item.sell_price * item.count)  + " ₽": ""}</span>
                                                                                                        </div>
                                                                                                    : ""
                                                                                                }
                                                                                                <div
                                                                                                    onClick={e => deleteAuthItemController(item, user.user.id)}
                                                                                                    className={"basket_page_trash_container"}>
                                                                                                    <TrashBasket/>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    : ""
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        : ""
                                                    }
                                        </div>
                                                </div>
                                             {
                                                 recommend.length > 0?
                                                     <div className={"basket_page_slider_block"}>
                                                         <div className={"basket_page_slider_header"}>
                                                             <span>Вам также может понравиться</span>
                                                             {
                                                                 recommend.length > 3 ?
                                                                     <div className={"basket_page_slider_buttons"}>
                                                                     <button className={"basket_page_slider_left"} >
                                                                         <ArrowLeft />
                                                                     </button>
                                                                     <button className={"basket_page_slider_right"} >
                                                                         <ArrowRight />
                                                                     </button>
                                                                     </div>
                                                                     : ""
                                                             }
                                                         </div>
                                                         <Swiper
                                                             modules={[Navigation]}
                                                             navigation={{
                                                                 nextEl: '.basket_page_slider_left',
                                                                 prevEl: '.basket_page_slider_right',
                                                             }}
                                                             spaceBetween={0}
                                                             slidesPerView={3}

                                                             className={"basket_page_slider_body"}

                                                         >
                                                             {
                                                                 recommend.map((item, index) => {
                                                                     return (
                                                                         <SwiperSlide>
                                                                             <RecommendItem
                                                                                 item={item}
                                                                                 index={index}
                                                                                 key={item.id}
                                                                             />
                                                                         </SwiperSlide>
                                                                     )
                                                                 })
                                                             }
                                                         </Swiper>
                                                     </div> : ""
                                             }

                                                </div>
                                               

                                                <div className={!isUsePromo ? "checkout_basket_page_wrapper active" : "checkout_basket_page_wrapper"}>
                                                        <div className={"checkout_basket_page_header"}>
                                                        <span>Бонусы</span>
                                                        <img alt='' src='' /> 
                                                        </div>
                                                    <Checkout/>
                                                    {
                                                        true ?
                                                        <BasketOneStepCalc basket={basket}
                                                        user={user}
                                                        basketItems={basket.activeBasketItems}
                                                        nameButton={basket.information.filter(item => item.num === basket.startBasketNum)[0] ? basket.information.filter(item => item.num === basket.startBasketNum)[0].button_text : "оформить заказ"}
                                                        // activeNum={activeNum}
                                                        // setActiveNum={setActiveNum}
                                                        information={basket.information}
                                                        goOrder={basketGoOneStep}
                                                    />
                                                        :
                                                        <button disabled className={"basket_btn_disable"}>Выберите товары</button>
                                                    }
                                                 
                                                            </div>
                                                <div className={isUsePromo ? "basket_mobile_block " : "basket_mobile_block hide"}>
                                                <div className={ isAvtivePromo ? "basket_mobile_block_first hide" : "basket_mobile_block_first"}>
                                                    <button className={"basket_mobile_block_button"}>
                                                        <span onClick={()=> setIsActivePromo(true)}>Промокод и бонусы</span>
                                                        <ArrowRight />
                                                    </button>
                                                    {
                                                        basket.selectedItems.length > 0 ?
                                                        <div
                                                            onClick={e => basketGoOneStep()}
                                                            className={basket.basketOverPrice ? "one_step_go_order_page disabled" : "one_step_go_order_page"}>
                                                            <span className={"one_step_dot"}>К оформлению ∙ {pluralize(basket.selectedItems.length, ['товар', 'товара', 'товаров'])}  </span>
                                                            <span>{basket.totalPrice} ₽</span>
                                                        </div>
                                                        :
                                                        <button disabled className={"basket_btn_disable"}>Выберите товары</button>
                                                    }
                                                    {basket.activeBasketItems.length && <Split/>}
                                                    </div>    
                                                    <div className={isAvtivePromo ? "basket_mobile_block_second active" :  "basket_mobile_block_second"}>
                                                    <Checkout />
                                                    <div className={"basket_mobile_block_buttons"}>
                                                        <button className={"basket_mobile_block_back"} onClick={() => setIsActivePromo(false)}>
                                                            <ArrowLeft />
                                                        </button>
                                                        <button className={"basket_mobile_block_more"} onClick={() =>setIsUsePromo(false)}>Применить</button>
                                                    </div>
                                                    </div>    
                                                    </div>            
                                                                                    </div>
                                        : ""
                                    }

                                        {/* <BasketOneStepCalc
                                            basket={basket}
                                            user={user}
                                            basketItems={basket.activeBasketItems}
                                            nameButton={basket.information.filter(item => item.num === basket.startBasketNum)[0] ? basket.information.filter(item => item.num === basket.startBasketNum)[0].button_text : "оформить заказ"}
                                            // activeNum={activeNum}
                                            // setActiveNum={setActiveNum}
                                            information={basket.information}
                                        /> */}
                                </div> 
                                :
                                <div className={ basket.startBasketNum === 4 ? "wrapper_container_limiter finish_step" : "wrapper_container_limiter"}>

                                    <div id={"BPW_wrapper"} className={"basket_wrapper_for_items"}>
                                        <div className={"heading_basket_page"}>
                                            <span className={"basket_title_text"}>{activePageInformation.name}</span>
                                        </div>
                                        <div className={"controll_indicator_wrapper"}>
                                            <div className={"basket_and_order_controll_indicator"}>
                                                <div className={"unifying_line"}>

                                                </div>
                                                {
                                                    basket.information ? basket.information.slice(1).map((item, index) => {
                                                            return (
                                                                <div
                                                                    key={item.num} className={"one_controll_indicator_item"}>
                                                                    <div className={"indicator_one_item_blob_wrapper"}>
                                                                        <div className={"emptiness_container left"}></div>
                                                                        <div
                                                                            className={basket.startBasketNum >= item.num ? "indicator_one_item_blob active" : "indicator_one_item_blob"}></div>
                                                                        <div className={"emptiness_container right"}></div>
                                                                    </div>
                                                                    <div className={"indicator_one_item_name"}>
                                                                        {item.vis_name}
                                                                    </div>
                                                                </div>

                                                            )
                                                        }) : ""
                                                }
                                            </div>
                                        </div>


                                        {
                                            basket.startBasketNum === 2 ?
                                                <div className={"order_delivery_wrapper"}>
                                                 <span
                                                     className={"order_delivery_heading"}>
                                                     1. Доставка
                                                 </span>
                                                    <div className={"order_delivery_city_wrapper"}>
                                                        <span className={"order_delivery_static_field_name"}>Город:</span>
                                                        <span
                                                            className={"order_delivery_static_city_name"}>{delivery.cityInfo ? delivery.cityInfo.value : "Выберите город" } </span>
                                                    </div>
                                                    <div className={"city_error_validator"}>
                                                        {delivery.cityInfoError ? delivery.cityInfoError : "" }
                                                    </div>
                                                    <div className={"order_delivery_change_city_button_wrapper"}>
                                                 <span
                                                     onClick={e => callChangeCityModal(1)}
                                                     className={"order_delivery_change_city_button"}>
                                                     Изменить город
                                                 </span>
                                                    </div>
                                                    <DeliveryMethods />
                                                    <div className={"basket_controll_wrapper"}>
                                                        <div
                                                            onClick={e => basket.upStepPage(basket.startBasketNum)}
                                                            className={ delivery.active ? "basket_controll_next_step" : "basket_controll_next_step one_step unactive"}>
                                                            Далее
                                                        </div>
                                                    </div>

                                                </div>
                                            : ""
                                        }
                                        {
                                            basket.startBasketNum === 3 ?
                                                <div className={"basket_controll_third_step_wrapper"}>
                                                    <OrderUserFields/>
                                                    <div className={"basket_controll_wrapper two_step"}>
                                                        <div
                                                            onClick={e => basket.downStepPage(basket.startBasketNum)}
                                                            className={"basket_controll_next_step left"}>
                                                            Назад
                                                        </div>
                                                        <div
                                                            onClick={e => basket.upStepPage(basket.startBasketNum)}
                                                            className={"basket_controll_next_step right"}>
                                                            Далее
                                                        </div>
                                                    </div>
                                                </div>
                                            : ""
                                        }
                                        {
                                            basket.startBasketNum === 4 ?

                                                <SelectPayType
                                                    forwardedRef={errRef}
                                                    downStepPage={e => basket.downStepPage(basket.startBasketNum)}
                                                />
                                            : ""
                                        }

                                    </div>
                                    <div
                                        className={"basket_page_right_column"}>
                                        <CalcBasket
                                            forwardedRef={errRef}
                                            user={user}
                                            basketItems={basket.activeBasketItems}
                                            nameButton={basket.information.filter(item => item.num === basket.startBasketNum)[0] ? basket.information.filter(item => item.num === basket.startBasketNum)[0].button_text : "оформить заказ"}
                                            // activeNum={activeNum}
                                            // setActiveNum={setActiveNum}
                                            information={basket.information}

                                        />
                                    </div>
                                </div>
                        }

                        {
                            basket.disabledBasketItems && basket.disabledBasketItems.length > 0 && basket.startBasketNum ===1 ?
                            <div className={"basket_one_step_disabled"}>
                                <div className={"basket_and_order_container_wrappers"}>
                                    <div className={"basket_count_items_and_clear_line"}>
                                        <span className={"basket_page_counter disabled_red"}>Нет в наличии</span>
                                        <span
                                            onClick={e => basket.removeAllItems('disable')}
                                            className={"basket_page_clear_basket"}>Очистить всё</span>
                                    </div>
                                   
                                    <div className={"basket_page_for_product_list"}>
                                        {
                                            basket.disabledBasketItems[0] ? basket.disabledBasketItems.map((item, index) => {
                                                    return (
                                                        
                                                        <div id={getOfferId(item)} key={index}
                                                            className={"basket_page_one_disabled_item_container"}>
                                                                <div className={"basket_page_disable_content_container"}>
                                                            <div className={"basket_page_image_preview"}>
                                                                <img
                                                                    src={process.env.REACT_APP_API_URL + "uploads/images/" + item.item.product.id + "/" + item.item.id + "/" + "214_" + item.item.img_preview}
                                                                    alt={'YouWanna - ' +(item.item.name ? item.item.name : '')}/>
                                                            </div>

                                                            <div className={"basket_page_container_price_and_basket"}>
                                                                <div className={"basket_page_one_item_content_section"}>
                                                                    <div className={"basket_page_disable_text_container"}>
                                                                        <div className={"basket_page_content_text_information_disabled"}>
                                                                            <div className={"product_name_articul_color"}>
                                                                                <div className={"basket_page_disabled_name_container"}>
                                                                                    {ucFirst(item.item.name)}
                                                                                </div>
                                                                                <div className={"basket_page_articul_container"}>
                                                                                    <span>Артикул: {" " + item.item.product.articul}</span>
                                                                                </div>
                                                                                {
                                                                                    settings.projectWidth && settings.projectWidth < 381 ?
                                                                                        <div className={"basket_page_color_container"}>
                                                                                            <div className={"basket_page_disabled_info_container"}>
                                                                                                <span>Товар закончился</span>
                                                                                            </div>
                                                                                            <div
                                                                                                className={"delate_wrapper"}>
                                                                                                <div 
                                                                                                    onClick={e => deleteAuthItemController(item, user.user.id)}
                                                                                                    className={"basket_page_trash_container"}
                                                                                                >
                                                                                                    <TrashBasket/>
                                                                                                </div>
                                                                                            </div>      
                                                                                        </div>
                                                                                    : 
                                                                                        <div className={"basket_page_disabled_info_container"}>
                                                                                            <span>Товар закончился</span>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                settings.projectWidth && settings.projectWidth > 445 ?
                                                                                    <div className={"basket_page_notification_button"}>
                                                                                        <span 
                                                                                            className={"product_page_add_to_basket notification_button"}
                                                                                            onClick={e => addToFieldsProductModal(item.item,user.user.id,item.currentSize, 1)}
                                                                                        >
                                                                                            Уведомить о поступлении
                                                                                        </span>
                                                                                    </div>
                                                                                : ""

                                                                            }
                                                                        </div>
                                                                            {
                                                                                settings.projectWidth && settings.projectWidth > 380 ?
                                                                                    <div
                                                                                        className={"delate_wrapper"}>
                                                                                        <div 
                                                                                            onClick={e => deleteAuthItemController(item, user.user.id)}
                                                                                            className={"basket_page_trash_container"}
                                                                                        >
                                                                                            <TrashBasket/>
                                                                                        </div>
                                                                                    
                                                                                    </div>
                                                                                : ""
                                                                            }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                            {
                                                                settings.projectWidth && settings.projectWidth < 446 ?
                                                                    <div className={"basket_page_notification_button"}>
                                                                        <span 
                                                                            className={"product_page_add_to_basket notification_button"}
                                                                            onClick={e => addToFieldsProductModal(item.item,user.user.id,item.currentSize, 1)}
                                                                        >
                                                                            Уведомить о поступлении
                                                                        </span>
                                                                    </div>
                                                                : ""

                                                            }
                                                    </div>
                                                    )
                                            })
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            : ""
                        }

                    </div>
                   
                    </div>

                   
                    :
                    <div>
                    <div className={"basket_non_product"} style={{height: (basket.disabledBasketItems.length === 0) ? '100%' : ""}}>
                        <h1 className={"title_basket_non_product"}>Ваша корзина пустая</h1>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_35_17060)">
                                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 10H9.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 10H15.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.5 15.2497C9.82588 14.9171 10.2148 14.6529 10.6441 14.4725C11.0734 14.2921 11.5344 14.1992 12 14.1992C12.4656 14.1992 12.9266 14.2921 13.3559 14.4725C13.7852 14.6529 14.1741 14.9171 14.5 15.2497" stroke="black" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_35_17060">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span className={"text_basket_non_product"}>
                            Начните с главной страницы или воспользуйтесь поиском, чтобы найти что-то конкретное
                        </span>
                        <Link to={'/'} className={"button_basket_non_product"}><noindex>На главную</noindex></Link>
                    </div>
                    {
                            basket.disabledBasketItems && basket.disabledBasketItems.length > 0 ?
                            <div className={"content_basket_page_wrapper"}>
                            <div className={"basket_one_step_disabled"}>
                                <div className={"basket_and_order_container_wrappers"}>
                                    <div className={"basket_count_items_and_clear_line"}>
                                        <span className={"basket_page_counter disabled_red"}>Нет в наличии</span>
                                        <span
                                            onClick={e => basket.removeAllItems('disable')}
                                            className={"basket_page_clear_basket"}>Очистить всё</span>
                                    </div>
                                    <div className={"basket_page_for_product_list"}>
                                        {
                                            basket.disabledBasketItems[0] ? basket.disabledBasketItems.map((item, index) => {
                                                    return (
                                                        <div id={getOfferId(item)} key={index}
                                                            className={"basket_page_one_disabled_item_container"}>
                                                                <div className={"basket_page_disable_content_container"}>
                                                            <div className={"basket_page_image_preview"}>
                                                                <img
                                                                    src={process.env.REACT_APP_API_URL + "uploads/images/" + item.item.product.id + "/" + item.item.id + "/" + "214_" + item.item.img_preview}
                                                                    alt={'YouWanna - ' +(item.item.name ? item.item.name : '')}/>
                                                            </div>

                                                            <div className={"basket_page_container_price_and_basket"}>
                                                                <div className={"basket_page_one_item_content_section"}>
                                                                    <div className={"basket_page_disable_text_container"}>
                                                                        <div className={"basket_page_content_text_information_disabled"}>
                                                                            <div className={"product_name_articul_color"}>
                                                                                <div className={"basket_page_disabled_name_container"}>
                                                                                    {ucFirst(item.item.name)}
                                                                                </div>
                                                                                <div className={"basket_page_articul_container"}>
                                                                                    <span>Артикул: {" " + item.item.product.articul}</span>
                                                                                </div>
                                                                                {
                                                                                    settings.projectWidth && settings.projectWidth < 381 ?
                                                                                        <div className={"basket_page_color_container"}>
                                                                                            <div className={"basket_page_disabled_info_container"}>
                                                                                                <span>Товар закончился</span>
                                                                                            </div>
                                                                                            <div
                                                                                                className={"delate_wrapper"}>
                                                                                                <div 
                                                                                                    onClick={e => deleteAuthItemController(item, user.user.id)}
                                                                                                    className={"basket_page_trash_container"}
                                                                                                >
                                                                                                    <TrashBasket/>
                                                                                                </div>
                                                                                            </div>      
                                                                                        </div>
                                                                                    : 
                                                                                        <div className={"basket_page_disabled_info_container"}>
                                                                                            <span>Товар закончился</span>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                settings.projectWidth && settings.projectWidth > 446 ?
                                                                                    <div className={"basket_page_notification_button"}>
                                                                                        <span 
                                                                                            className={"product_page_add_to_basket notification_button"}
                                                                                            onClick={e => addToFieldsProductModal(item.item,user.user.id,item.currentSize, 1)}
                                                                                        >
                                                                                            Уведомить о поступлении
                                                                                        </span>
                                                                                    </div>
                                                                                : ""

                                                                            }
                                                                        </div>
                                                                        {
                                                                            settings.projectWidth && settings.projectWidth > 380 ?
                                                                                <div
                                                                                    className={"delate_wrapper"}>
                                                                                    <div 
                                                                                        onClick={e => deleteAuthItemController(item, user.user.id)}
                                                                                        className={"basket_page_trash_container"}
                                                                                    >
                                                                                        <TrashBasket/>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                            {
                                                                settings.projectWidth && settings.projectWidth < 446 ?
                                                                    <div className={"basket_page_notification_button"}>
                                                                        <span 
                                                                            className={"product_page_add_to_basket notification_button"}
                                                                            onClick={e => addToFieldsProductModal(item.item,user.user.id,item.currentSize, 1)}
                                                                        >
                                                                            Уведомить о поступлении
                                                                        </span>
                                                                    </div>
                                                                : ""

                                                            }
                                                    </div>
                                                    )
                                            })
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>
                            : ""
                        }
                    </div>
            }
        </div>
    );
});

export default Basket;