import React, {useEffect, useState} from 'react';
import './childProductOneItem.css'
import OneImageField from "../../../UI/oneImageField/OneImageField";
import {addImages, deleteImage} from "../../../../../http/imagesApi";
import {catalogHorizontalSizePreset, catalogProductSizePreset} from "../../../../../utils/consts";
import CompliteRemove from "../../../UI/compliteremove/CompliteRemove";
import OneImageHorizontalField from "../../../UI/OneImageHorizontalField/OneImageHorizontalField";
import MultiFilesUpload from "../../../UI/multifilesupload/MultiFilesUpload";
import {login} from "../../../../../http/userAPI";
import IsActiveIndicator from "../../../UI/isActiveIndicator/IsActiveIndicator";

const ChildProductOneItem = (props) => {


    let activePage = props.item.activePage;
    const [colorsTake,setColorsTake] = useState(false);

    const colorClick = (item,index) => {
        props.setOneProductCreate({...props.fields},
            [
                [props.fields.childProducts[props.index].colorId = item.id]
            ])
        setColorsTake(!colorsTake);
    }

   // ##########   OTHER IMAGES
    const [otherImages,setOtherImages] = useState([]);

    useEffect(() => {
        let imagesArray = JSON.parse(props.fields.childProducts[props.index].other_images);
        if(!imagesArray) {
            return false;
        }
        imagesArray = imagesArray.map((item,index) => {
            return {...item, id:index+1}
        })
       setOtherImages(imagesArray)
    },[])

    useEffect(() => {

        // console.log(otherImages)
        let jsonOtherImages = JSON.stringify(otherImages);
        props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].other_images = jsonOtherImages ])
    },[otherImages])

    const choiseClickAddNewObject = (object) => {
        // let reduceOtherImages = props.fields.childProducts[props.index].other_images;
        let reduceOtherImages = []

        if(otherImages !== null) {
            reduceOtherImages = [...otherImages];
            if(reduceOtherImages.length >= 8) {
                return false;
            }
        }
        reduceOtherImages.push({...object, id:reduceOtherImages.length+1});

        setOtherImages(reduceOtherImages)

    }
    // ##########   OTHER IMAGES


    const horizontalImageTemplate = {type:"horizontal", image:"", id:""}
    const doubleImagetemplate = {type:"double", one_image:"", two_image: "",id:""}

    const sizeController = (item) => {

        let addNew = true;

        props.fields.childProducts[props.index].child_product_size_field.forEach((eachItem,index) => {

            if(eachItem.size_name === item.size_name) {
                addNew = false;
            }
        })
        if(addNew) {
            let newSizeArray = [...props.fields.childProducts[props.index].child_product_size_field];
            newSizeArray.push({size_name:item.size_name,available:item.available,position:item.position})
            newSizeArray.sort((a,b) => a.position - b.position);
            props.setOneProductCreate({...props.fields},[props.fields.childProducts[props.index].child_product_size_field = newSizeArray ])

        }
    }
    const deleteSize = (item,index) => {
    // return false;
        props.fields.childProducts[props.index].child_product_size_field.forEach((eachItem,index) => {


            if(eachItem.size_name === item.size_name) {
                props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].child_product_size_field.splice(index, 1)])
            }
        })
    }

    const switchAvailableSize = (item,index,newValue) => {
        props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].child_product_size_field[index].available = newValue])
    }
    const switchActiveSize = (item,index,newValue) => {
        props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].child_product_size_field[index].isActive = newValue])
    }
    const switchActiveChildProduct = () => {
        // console.log("test")
        // console.log(props.fields.childProducts[props.index])
        props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].is_active = !props.fields.childProducts[props.index].is_active])
    }

    const switchUpdateMethod = () => {
        // console.log("test")
        // console.log(props.fields.childProducts[props.index])
        props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].autoupdate = !props.fields.childProducts[props.index].autoupdate])
    }
    const setImageField = (e,fieldName,catalogProductSizePreset) => {
        e.preventDefault()
        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (props.fields.childProducts[props.index][fieldName]){
            deleteImage(props.fields.childProducts[props.index][fieldName], props.parrentId, props.item.id).then(response  => {
                props.setOneProductCreate({...props.fields},  [props.fields.childProducts[props.index][fieldName] = null])
            })
        }

        addImages(formData,props.parrentId,props.item.id, catalogProductSizePreset).then(response => {
            if(response.message) {
                props.setOneProductCreate({...props.fields},  [props.fields.childProducts[props.index][fieldName] = response.fileName])
            }

        })
    }

    const uploadAndSetImage = (e, fieldname, catalogProductSizePreset, index) => {
        e.preventDefault()
        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);
        if(otherImages[index][fieldname]) {
            deleteImage(otherImages[index][fieldname], props.parrentId, props.item.id).then(response  => {
                setOtherImages([...otherImages], [otherImages[index][fieldname] = null] )
            })
        }
        addImages(formData,props.parrentId,props.item.id, catalogProductSizePreset).then(response => {
            if(response.message) {
                setOtherImages([...otherImages], [otherImages[index][fieldname] = response.fileName])
                // props.setOneProductCreate({...props.fields},  [props.fields.childProducts[props.index][fieldName] = response.fileName])
            }

        })
    }

    const deleteImageGenerator = (e, fieldName,index) => {
        deleteImage(otherImages[index][fieldName], props.parrentId, props.item.id).then(response  => {
            setOtherImages([...otherImages], [otherImages[index][fieldName] = null] )
            // props.setOneProductCreate({...props.fields},  [props.fields.childProducts[props.index][fieldName] = null])
        })
    }


    const deleteImageField = (e, fieldName) => {
        deleteImage(props.fields.childProducts[props.index][fieldName], props.parrentId, props.item.id).then(response  => {
            props.setOneProductCreate({...props.fields},  [props.fields.childProducts[props.index][fieldName] = null])
        })
    }





    const currentColorNameFilter = props.colors.filter(color => color.id === props.item.colorId);
    const nameColor = currentColorNameFilter[0] ? currentColorNameFilter[0].color_name : null;

    const childCategoriesHandler = (fieldName) => {
        props.setOneProductCreate({...props.fields},[props.fields.childProducts[props.index][fieldName] = !props.fields.childProducts[props.index][fieldName]])
    }

    const changePositionChooseBlocks = (id,parametr) => {

        let length = otherImages.length;
        if(parametr === "down" && id === length){
            // console.log("запрещено")
            return false;
        }

        if(parametr === "up" && id === 1){
            // console.log("запрещено")
            return false;
        }

        let reduceOtherImages = [...otherImages];

        // if(parametr === "up") {
        //
        // }
        reduceOtherImages = reduceOtherImages.map((item,index) => {

            if(parametr === "up") {
                if(index === id - 2 ) {
                    return {...item, id: item.id + 1}
                }
                if(index === id - 1 ) {
                    return {...item, id: item.id - 1}
                }
            }

            if(parametr === "down") {
                if(index === id - 1 ) {
                    return {...item, id: item.id + 1}
                }
                if(index === id ) {
                    return {...item, id: item.id - 1}
                }
            }


            return {...item};

        })

        reduceOtherImages.sort((a,b) => a.id - b.id )
        setOtherImages(reduceOtherImages)
    }



    const deleteOneChooseBlock = (item,index) => {
        if(item.type === horizontalImageTemplate.type) {
            deleteImage(item.image, props.parrentId, props.item.id).then(response  => {
                let reduceItems = [...otherImages];
                reduceItems.splice(index,1);
                reduceItems = reduceItems.map((item,index) => {
                    return {...item, id: index+1};
                })
                setOtherImages(reduceItems);
            })

        }

        if(item.type === doubleImagetemplate.type) {
            deleteImage(item.one_image, props.parrentId, props.item.id).then(response  => {
                deleteImage(item.two_image, props.parrentId, props.item.id).then(response  => {
                    let reduceItems = [...otherImages];
                    reduceItems.splice(index,1);
                    reduceItems = reduceItems.map((item,index) => {
                        return {...item, id: index+1};
                    })
                    setOtherImages(reduceItems);
                })
            })
        }

    }

    const getImageBlockTitle = (id) => {
        let titles = ["1. Каталог", "2. Street", "3. Обтравка"];
        if (id > 3) {
            return null;
        }
        return titles[id-1]
    }

    const changeHiddenStatus = (newValue) => {
        props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].price_hidden = newValue])
    }


    return (
        <div  className={activePage ? "product_one_item_container_f active" : "product_one_item_container_f disactive"}>

            {/*{console.log(props.item)}*/}
            <div className={"product_information"} >
                <div
                    onClick={e => switchActiveChildProduct()}
                    className={props.fields.childProducts[props.index].is_active ? "active_child_product active" : "active_child_product"}>
                    {
                        props.fields.childProducts[props.index].is_active ?
                            "Активно" :
                            "Выключено"
                    }
                </div>
                <div
                    onClick={e => switchUpdateMethod()}
                    className={props.fields.childProducts[props.index].autoupdate ? "active_child_product active" : "active_child_product"}>
                    {
                        props.fields.childProducts[props.index].autoupdate ?
                            "Способ обновления: Автоматический" :
                            "Способ обновления: Вручную"
                    }
                </div>
                <span className={"update_notificator"}>Если выбрать ручной способ обновления информации, в таком случае активность товаров не будет изменена из-за отсутствия остатков.</span>
                <span className={"product_color_info"}>СЕО</span>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Тайтл *</span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Укажите Title"}
                        value={props.item.title ? props.item.title : "" }
                        onChange={props.onChangeTitle}
                        type="text" name="" id=""/>
                </div>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Дескрипшен *</span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Укажите Description"}
                        value={props.item.description ? props.item.description : "" }
                        onChange={props.onChangeDescription}
                        type="text" name="" id=""/>
                </div>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Имя товара *</span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Укажите имя товара "}
                        value={props.item.name ? props.item.name : props.oneProductCreate.name }
                        onChange={props.onChangeName}
                        type="text" name="" id=""/>
                </div>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Код для URL (символьный код) *</span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Код для URL"}
                        value={props.item.code ? props.item.code : "" }
                        onChange={props.onChangeCode}
                        type="text" name="" id=""/>
                </div>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Размер скидки (указывается в %) </span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Укажите скидку в %, от 1 до 99"}
                        value={props.item.sell_value ? props.item.sell_value : "" }
                        onChange={props.onChangeSellValue}
                        type="text" name="" id=""
                    />
                </div>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Цена со скидкой (считается автоматически) </span>
                    <input
                        disabled={true}
                        className={"admin_field_styles disabled"}
                        placeholder={"Будет посчитана при вводе размера скидки"}
                        value={props.item.sell_price ? props.item.sell_price : "" }
                        onChange={props.onChangeSellPrice}
                        type="text" name="" id=""
                    />
                </div>
                
            {/*цвет*/}
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Цвет товара *</span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Выберите цвет"}
                        onClick={e => setColorsTake(!colorsTake)}
                        type="text" name="" id=""
                        value={nameColor ? nameColor : "Нет цвета"}
                        onChange={e => (false)}
                    />

                </div>
                <div className={"admin_inputs_wrapper"}>
                    <span className={"field_info"}>Сортировка. Только цифры. </span>
                    <input
                        className={"admin_field_styles"}
                        placeholder={"Укажите сортировку."}
                        value={props.item.sort ? props.item.sort : "" }
                        onChange={props.onChangeSort}
                        type="text" name="" id=""
                    />
                </div>

                <div className={colorsTake ? "div_wrapper_colors active" : "div_wrapper_colors disactive" }>
                    {props.colors.map((item,index) => {
                        return(
                            <div
                                className={"one_item_color_colors_selector"}
                                onClick={e => colorClick(item,index)}
                                key={index}>
                                {item.color_name}
                            </div>
                        );
                    })}
                </div>
                <div className={"admin_inputs_wrapper"}>
                        <div className={"wrap_for_row_field"}>
                            <span className={"field_info"}>haute couture </span>
                            <IsActiveIndicator 
                                active={props.fields.childProducts[props.index].haute_couture}
                                change={e => props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].haute_couture = !props.fields.childProducts[props.index].haute_couture])} />
                        </div>
                </div>
                <div className={"admin_inputs_wrapper"}>
                        <div className={"wrap_for_row_field"}>
                            <span className={"field_info"}>exclusive </span>
                            <IsActiveIndicator 
                                active={props.fields.childProducts[props.index].exclusive}
                                change={e => props.setOneProductCreate({...props.fields}, [props.fields.childProducts[props.index].exclusive = !props.fields.childProducts[props.index].exclusive])} />
                        </div>
                </div>
                <div className={"admin_inputs_wrapper"}>
                    <div className={"wrap_for_row_field"}>
                        <span className={"field_info"}>limited </span>
                        <IsActiveIndicator 
                            active={props.fields.childProducts[props.index].limited}
                            change={e => props.setOneProductCreate({...props.fields},
                                [props.fields.childProducts[props.index].limited = !props.fields.childProducts[props.index].limited])}
                        />
                    </div>
                </div>
                {/* HIDE PRICE CHECKBOX */}
                <div className={"admin_inputs_wrapper"}>
                        <div className={"wrap_for_row_field"}>
                            <span className={"field_info"}><span className={"red_sup"}>*</span> Скрывать цену </span>
                            <IsActiveIndicator active={props.fields.childProducts[props.index].price_hidden} change={e => changeHiddenStatus(!props.fields.childProducts[props.index].price_hidden)} />
                        </div>
                            <span className={"fields_text_aft"}>При включении, цена товара отображаться не будет</span>
                </div>

                <div className={"all_sizes_block"}>
                    <span className={"show_all_sizes"}>Нажатие на размер добавляет его.</span>
                    <div className={"wrapper_sizes_container_onprd"}>
                    {
                        props.size ?
                            props.size.map((item,index) => {
                                return(<div onClick={ e => sizeController(item) } className={"size_one_item_in_all"} key={index}> {item.size_name}</div>)
                            }) : ""
                    }
                    </div>
                </div>
                <div className={"active_sizes_in_childProduct"}>
                    <span className={"show_active_sizes"}>Выбранные (добавленные) размеры для этого товара</span>
                    <div className={"current_sizes_wrapper_active"}>
                        {
                            props.item.child_product_size_field ?
                                props.item.child_product_size_field.map((item,index) => {
                                    return(
                                        <div
                                            key={index}
                                            className={"wrapper_one_selected_size"}>
                                                <div
                                                    onClick={e => switchAvailableSize(item,index, !item.available)}
                                                    className={item.available ? "current_sizes_this_product" : "current_sizes_this_product noavailable" } > {item.size_name}

                                                </div>
                                                <div
                                                    key={index}
                                                 className={ item.isActive ? "switch_active_size active" : "switch_active_size"}
                                                 onClick={ e => switchActiveSize(item,index, !item.isActive)}
                                                >
                                                    {
                                                        item.isActive ?
                                                            "Активно"
                                                            : "Выключено"
                                                    }

                                                </div>
                                                <div className={"one_item_controll_delete"}
                                                     onClick={e => deleteSize(item, index)}
                                                > + </div>
                                        </div>


                                    )
                                }) : ""
                        }
                    </div>

                </div>
                <span className={"child_category_heading"}>Свойства для торговых предложений</span>
                <div className={"child_category_container"}>
                    <div
                        onClick={e => childCategoriesHandler("new_product")}
                        className={ props.fields.childProducts[props.index].new_product  ?  "child_item_category_indicator active" : "child_item_category_indicator" }>
                            Новинка
                    </div>
                    <div
                        onClick={e => childCategoriesHandler("bestseller")}
                        className={ props.fields.childProducts[props.index].bestseller  ?  "child_item_category_indicator active" : "child_item_category_indicator" }>
                        Бестселлер
                    </div>
                    <div
                        onClick={e => childCategoriesHandler("coming_soon")}
                        className={ props.fields.childProducts[props.index].coming_soon  ?  "child_item_category_indicator active" : "child_item_category_indicator" }>
                        Скоро в наличии
                    </div>
                    <div
                        onClick={e => childCategoriesHandler("unisex")}
                        className={ props.fields.childProducts[props.index].unisex  ?  "child_item_category_indicator active" : "child_item_category_indicator" }>
                        UNISEX
                    </div>

                </div>
                <div className={"page_imitator"}>

                    <div className="wrapper_top_images_container">
                        <div className={"wrapper_two_images_t"}>

                            <OneImageField
                                text={"Превью товара"}
                                image={props.fields.childProducts[props.index].img_preview ?
                                    process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                    props.fields.childProducts[props.index].id+"/"+props.fields.childProducts[props.index].img_preview  : null }
                                onChange={ e => setImageField(e,'img_preview', catalogProductSizePreset)}
                                deleteImage={ e => deleteImageField(e,'img_preview' )}
                            />
                            <OneImageField text={"При наведении"}
                                           image={props.fields.childProducts[props.index].img_hover ?
                                               process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                               props.fields.childProducts[props.index].id+"/"+props.fields.childProducts[props.index].img_hover  : null }
                                           onChange={ e => setImageField(e,'img_hover',catalogProductSizePreset)}
                                           deleteImage={ e => deleteImageField(e,'img_hover')}
                            />
                            <OneImageField text={"Первая картинка"}
                                           image={props.fields.childProducts[props.index].img_first ?
                                               process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                               props.fields.childProducts[props.index].id+"/"+props.fields.childProducts[props.index].img_first  : null }
                                           onChange={ e => setImageField(e,'img_first',catalogProductSizePreset)}
                                           deleteImage={ e => deleteImageField(e,'img_first')}
                            />
                        </div>

                    </div>

                    <div className={"choosing_which_field_add"}>
                        <span className={"choosing_heading"}>
                            Чтобы добавить блок - нажмите, на одну из двух моделей
                        </span>
                        <span className={"atention_text_choosing"}>
                           Рекомендуем в целях оптимизации не создавать больше 6 блоков. Максимум - до 8 блоков.
                        </span>

                        <div className={"wrapper_choosing_blocks"}>
                            {/*<div*/}
                            {/*    onClick={ e => choiseClickAddNewObject(Object.assign({}, horizontalImageTemplate))}*/}
                            {/*    className={"image_imitator_choosing"}>*/}
                            {/*    <div className="one_image_choosing">*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                            <div
                                onClick={ e => choiseClickAddNewObject(Object.assign({}, doubleImagetemplate))}
                                className={"image_imitator_choosing"}>
                                <div className="two_image_choosing">

                                </div>
                                <div className="two_image_choosing">

                                </div>
                                <div className="two_image_choosing">

                                </div>
                                <div className="two_image_choosing">

                                </div>
                            </div>
                        </div>
                        <div className={"choosing_generate_blocks"}>
                            {
                                otherImages && otherImages[0] ?
                                    otherImages.map((item,index) => {
                                        if(item.type === "horizontal") {
                                            return( <div key={index} className={"bit_image_child_products"}>
                                                        <div className={"section_choose_controller"}>
                                                            <div
                                                                onClick={e => deleteOneChooseBlock(item,index)}
                                                                className={"choose_delete_controller all_chose_ct"}></div>
                                                            <div
                                                                onClick={e => changePositionChooseBlocks(item.id, "up")}
                                                                className={"choose_up_controller all_chose_ct"}></div>
                                                            <div
                                                                onClick={e => changePositionChooseBlocks(item.id, "down")}
                                                                className={"choose_down_controller all_chose_ct"}></div>
                                                        </div>
                                                        <OneImageHorizontalField
                                                            image={ otherImages[index].image ?
                                                                process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                                                props.fields.childProducts[props.index].id+"/"+otherImages[index].image  : null }
                                                            onChange={ e => uploadAndSetImage(e,'image',catalogHorizontalSizePreset, index)}
                                                            deleteImage={ e => deleteImageGenerator(e,'image',index)}
                                                        />
                                                   </div>)
                                        }
                                        if(item.type === "double") {
                                            return(
                                                <div className={"double_image_gen_wrapper"} key={index}>
                                                    <div className={"section_choose_controller"}>
                                                        <div
                                                            onClick={e => deleteOneChooseBlock(item,index)}
                                                            className={"choose_delete_controller all_chose_ct"}></div>
                                                        <div
                                                            onClick={e => changePositionChooseBlocks(item.id, "up")}
                                                            className={"choose_up_controller all_chose_ct"}></div>
                                                        <div
                                                            onClick={e => changePositionChooseBlocks(item.id, "down")}
                                                            className={"choose_down_controller all_chose_ct"}></div>
                                                    </div>
                                                    <OneImageField text={"Каталог"}
                                                                   image={ otherImages[index].one_image ?
                                                                       process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                                                       props.fields.childProducts[props.index].id+"/"+otherImages[index].one_image  : null }
                                                                   onChange={ e => uploadAndSetImage(e,'one_image',catalogProductSizePreset, index)}
                                                                   deleteImage={ e => deleteImageGenerator(e,'one_image',index)}
                                                    />
                                                    <OneImageField text={"Street"}
                                                                   image={ otherImages[index].two_image ?
                                                                       process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                                                       props.fields.childProducts[props.index].id+"/"+otherImages[index].two_image  : null }
                                                                   onChange={ e => uploadAndSetImage(e,'two_image',catalogProductSizePreset, index)}
                                                                   deleteImage={ e => deleteImageGenerator(e,'two_image',index)}
                                                    />
                                                    <OneImageField text={"Обтравка"}
                                                                   image={ otherImages[index].three_image ?
                                                                       process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                                                       props.fields.childProducts[props.index].id+"/"+otherImages[index].three_image  : null }
                                                                   onChange={ e => uploadAndSetImage(e,'three_image',catalogProductSizePreset, index)}
                                                                   deleteImage={ e => deleteImageGenerator(e,'three_image',index)}
                                                    />
                                                    <OneImageField text={""}
                                                                   image={ otherImages[index].four_image ?
                                                                       process.env.REACT_APP_API_URL+"uploads/images/"+props.parrentId+"/"+
                                                                       props.fields.childProducts[props.index].id+"/"+otherImages[index].four_image  : null }
                                                                   onChange={ e => uploadAndSetImage(e,'four_image',catalogProductSizePreset, index)}
                                                                   deleteImage={ e => deleteImageGenerator(e,'four_image',index)}
                                                    />

                                                </div>)
                                        }
                                    })
                                     : ""
                            }
                        </div>
                    </div>



                    {/*<div className={"other_images_container"}>*/}
                    {/*    <MultiFilesUpload*/}
                    {/*        images={ props.fields.childProducts[props.index].other_images ? props.fields.childProducts[props.index].other_images : null }*/}
                    {/*        parentId={props.parrentId}*/}
                    {/*        id={props.item.id}*/}
                    {/*        index={props.index}*/}

                    {/*        oneProduct={props.fields}*/}
                    {/*        setOneProduct={props.setOneProductCreate}*/}
                    {/*    />*/}
                    {/*</div>*/}


                </div>


            </div>

        </div>

    );

};

export default ChildProductOneItem;


